@import url(https://fonts.googleapis.com/css2?family=Work+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
    scroll-behavior: smooth;
}

.App {
    font-family: 'Work Sans';
    background-color:black;
    color: #cec390;
    /* color:#ffe7b0; */
    /* color: #ebc96b; */
    line-height: 1.5em;
}

.Hero {
    text-align: center;
    transition: opacity 1s ease-in-out;
}

.Hero img {
    height:100vh;
    width: 100%;
    object-fit: cover;
}

.photo-credit {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: rgb(142, 142, 142);
    text-shadow: 1px 1px 2px rgba(0,0,0,0.8);
    font-size: 0.58rem;
}

.Hero-top {
    opacity: .5;
}

.Wide img {
    width: 100%;
}

.Section img {
    margin:0;
}

.Navbar {
    display: block;
    position: absolute;
    text-align: right;
    top:10vh;
    right:8%;
    color: black;
    background-color: rgb(0,0,0,0);
    a{color:black;}
    a:active{color:black;}
    /* transition: opacity 1s ease-in-out, background-color 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out, background-color 1s ease-in-out;
    -webkit-transition: opacity 1s  ease-in-out, background-color 1s ease-in-out; */
    z-index: 1;
}

.Title {
    text-transform: uppercase;
    font-size:3em;
    letter-spacing: 0.08em;
    font-family: 'Optima';
}

.Nav {
    opacity: 0.8;
    font-size: 1.2em;
    text-transform: lowercase;
}

.Navbar-top {
    text-align: left;
    top: 0em;
    left: 0em;
    color: #cec390;
    padding-left: 10em;
    padding-top: .5em;
    width: 100%;
    position: fixed;
    background-color: rgb(0,0,0,0.9);
    a{color:#cec390;;}
    a:active{color:#cec390;;}
}

.Title-top {
    display: inline-block;
}

.Nav-top {
    margin-left: 5em;
    display: inline-block;
}

.Title-top {
	font-size: 1.5em;
}

.Nav ul {
    list-style-type: none;
    line-height: 3em;
    padding:0;
}

.Nav li {
    display: inline-block;
    padding-left:1.7em;
    letter-spacing: 0.03em;
}

.Navbar a {
    transition: color 0s ease-in-out;
    -moz-transition: color 0s ease-in-out;
    -webkit-transition: color 0s ease-in-out;
}

a {
    color: #dbd5bc;
    border-bottom: 1.5px dotted #aaa487;
    text-decoration: none;
    transition: color .4s ease-in-out;
    -moz-transition: color .4s ease-in-out;
    -webkit-transition: color .4s ease-in-out;
}

.Nav a {
    border-bottom: 0px;
}

a:hover {
    color: white;
}

.Anchor {
    display: block;
    position: relative;
    top: -7em;
    visibility: hidden;
}

h2 {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 2em;
    font-family: 'Optima';
    padding-top: 3.5em;
    margin: 0;
}

.Section {
    display: block;
    width: 50%;
    padding-left: 25%;
    padding-right: 25%;
    padding-bottom: 4em;
}

.Section p {
    opacity: .9;
}

.Quotes {
	padding-top: .5em;
    padding-bottom: 0em;
	text-align: center;
	background-color: rgba(255,255,255,0);
}
.Quotes .quote {
	font-style: italic;
	font-size: 1.5em;
	font-family: 'Optima';
    margin-bottom: 0;
	/*background-color: rgba(255,255,255,0.2);*/
}
.Quotes .quoted {
	text-transform: uppercase;
	letter-spacing: 0.05em;
    padding-bottom: 1em;
}

.About {
	background-color: rgba(255,255,255,0.07);
}

.About h2 {
    padding-top: 2.5em;
}

.About p {
    width:55%
}

.About img {
    position: absolute;
    padding-left: 30%;
    display: inline-block;
    width: 20%
}

.Media {
    background-color: rgba(255,255,255,0.1);
}

h3 {
    margin-top: 4em;
    font-weight: 100;
    opacity: .9;
    padding-bottom: 1em;
    border-bottom: 1px solid grey;
}

.container {
    text-align:center;
}

input, textarea {
    width: 80%;
    padding: 0.5em;
    background-color: rgba(255,255,255,0.1);
    color: rgba(255,255,255,0.5);
    margin-top: 2em;
    font-family:'Work Sans', sans-serif;
    border: none;
}

.Video {
    width: 35em;
    height: 19em;
}

.Spotify {
    width: 35em;
    height: 14em;
}

.MP3 {
    width: 30em;
    height: 4em;
}

#submit {
    background-color: rgba(255,255,255,0.08);
}

.Footer {
    text-align: center;
    color: rgba(255,255,255,0.5);
    text-transform: uppercase;
    padding-top: 1em;
    padding-bottom: 1em;
}


@media screen and (min-width: 961px) and (max-width: 1320px) {
    .Section {
        padding-left:20%;
        width: 60%;
        padding-right:20%;
    }
    .About img {
        padding-left: 38%;
    }
    .Title {
    	font-size: 2.5em;
    }
    .Title-top {
		font-size: 1.5em;
	}
}

@media screen and (min-width: 737px) and (max-width: 960px) {
    .Section {
        padding-left:10%;
        width: 80%;
        padding-right:10%;
    }
    .About img {
        padding-left: 50%;
    }
    .Navbar {
        left:40%; 
    }
    .Navbar-top {
        left: 0em;
    }
    .Nav-top {
        margin-left:2.5em;
    }
    .Nav-top li {
        display: inline-block;
        padding-right: 1em;
    }
    .Title {
    	font-size: 2em;
    }
    .Title-top {
		font-size: 1.5em;
	}
}
@media screen and (min-width: 493px) and (max-width: 736px) {
    .Section {
        padding-left:10%;
        width: 80%;
        padding-right:10%;
    }
    .About p {
        width: 100%;
    }
    .About img {
        display: none;
    }
    .Navbar {
        left: 25%;
    }
    .Navbar-top {
        padding-left:2em;
        left: 0em;
        font-size: 0.75em;
    }
    .Nav-top {
        margin-left:2em;
    }
    .Nav li {
        display: block;
    }
    .Nav-top li {
        display: inline-block;
        padding-right: 1em;
    }
    .Video, .Spotify, .MP3 {
        width: 20em;
    }
    .Video, .Spotify {
        height:13em;
    }
    .Title {
    	font-size: 1.7em;
    }
    .Title-top {
		font-size: 1.3em;
	}
}

@media screen and (max-width: 492px) {
    .Section {
        padding-left:10%;
        width: 80%;
        padding-right:10%;
    }
    .About p {
        width: 100%;
    }
    .About img {
        display: none;
    }
    .Navbar {
        left: 25%;
    }
    .Navbar-top {
        padding-left:2em;
        left: 0em;
        font-size: 0.75em;
    }
    .Nav-top {
        margin: 0;
        padding: 0;
        line-height: 1em;
    }
    .Title-top {
        margin-bottom: 0;
    }
    .Nav li {
        display: block;
    }
    .Nav-top li {
        display: inline-block;
        padding-right: 1em;
    }
    .Video, .Spotify, .MP3 {
        width: 20em;
    }
    .Video, .Spotify {
        height:13em;
    }
    .Title {
    	font-size: 1.7em;
    }
    .Title-top {
		font-size: 1.2em;
	}
}
